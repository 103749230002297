<template>
  <DashboardContainer>
    <addTextTemplate />
  </DashboardContainer>
</template>

<script>
import DashboardContainer from "../containers/DashboardContainer";
import addTextTemplate from "../components/marketing/addTextTemplate";

export default {
  name: "addTemplate",
  beforeCreate: function () {
    var auth = this.$storage.get("auth");
    if (!auth) {
      this.$router.push("/login");
    } else {
      if (!auth.token) {
        this.$router.push("/login");
      }
    }
  },
  components: {
    DashboardContainer,
    addTextTemplate,
  },
};
</script>